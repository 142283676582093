html,
body {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;

    font-size: 16px;
    font-family: 'SourceSansPro', 'Helvetica Neue', Arial;
}

* {
    box-sizing: border-box;
}
